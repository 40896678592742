import { IconButton, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../../assets/logo.png";
import { useState } from "react";
import { themeColors } from "../../../../theme/theme";
import { Close } from "@mui/icons-material";
import { language } from "../../../../language/strings";
import MenuLink from "./MenuLink";

interface MobileHeaderProps {

};

const styles = {
    menu: {
        position: "absolute", 
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        pr: 2,
        m: "0px !important",
        zIndex: 100,
        backgroundColor: themeColors.secondary,
        color: "white",
        alignItems: "flex-start",
        "& .MuiStack-root": {
            marginLeft: 0
        }
    },
    menuHeader: {
        backgroundColor: "white",
        justifyContent: "space-between",
        width: "100%"
    }
}

export default function MobileHeader(props: MobileHeaderProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    function handleClose() {
        setAnchorEl(null)
    }
    return <Stack direction={"row"} justifyContent={"space-between"}>
        <img src={logo} alt="Projecten Rob Wens" height="30px" style={{margin: "10px"}}/>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} >
            <MenuIcon color="primary" />
        </IconButton>
        <Stack direction={"column"} sx={[{display: open ? "flex" : "none"}, styles.menu]}>
            <Stack direction="row" sx={styles.menuHeader} >
                <img src={logo} alt="Projecten Rob Wens" height="30px" style={{marginLeft: "10px"}}/>
                <IconButton onClick={handleClose}>
                    <Close color="primary"/>
                </IconButton>
            </Stack>
            <div onClick={handleClose}><MenuLink name={language.menu.home} link="home" /></div>
            <div onClick={handleClose}><MenuLink name={language.menu.about} link="about" /></div>
            <div onClick={handleClose}><MenuLink name={language.menu.photos} link="photos" /></div>
            <div onClick={handleClose}><MenuLink name={language.menu.contact} link="contact" /></div>
        </Stack>
    </Stack> 
}