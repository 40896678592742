import { Grid, Link, Stack, Typography } from "@mui/material";
import { language } from "../../../../language/strings";
import { themeColors } from "../../../../theme/theme";

interface FooterProps {};

const styles = {
    footer: {
        backgroundColor: themeColors.secondary,
        borderTop: "1px solid white"
    },
    text: {
        color: "white",

    }
}

export default function Footer(props: FooterProps) {
    return <Grid container py={4} px={{xs: 2, lg: 16}} sx={styles.footer}>
        <Grid item xs={12} lg={6}>
            <Stack direction="column" alignItems={"flex-start"} spacing={0}>
                <Typography sx={styles.text}>{language.contact.mail}</Typography>
                <Typography sx={styles.text}>{language.contact.phone}</Typography>
                <Typography sx={styles.text}>{language.contact.address}</Typography>
                <Typography sx={styles.text}>{language.contact.vat}</Typography>
            </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
            <Stack direction="column" alignItems={"flex-start"} spacing={0}>
                <Link href="/sitemap.xml">{language.sitemap}</Link>
                <Link href="https://agits.be">© {new Date().getFullYear()} {language.copyright}</Link>
            </Stack>
        </Grid>
    </Grid>
}