import { useIsMobile } from "../../../../utils/hooks";
import MobileHeader from "./MobileHeader";
import WebHeader from "./WebHeader";

interface HeaderProps {}

export default function Header(props: HeaderProps) {
        const isMobile = useIsMobile();

        if (isMobile) {
            return <MobileHeader />
        } else {
            return <WebHeader />
        }
}