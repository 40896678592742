import { Stack } from "@mui/material"
import { themeColors } from "../../../../theme/theme"
import { HouseOutlined, AccountBalanceOutlined, MailOutlined, PhoneOutlined } from "@mui/icons-material"
import { language } from "../../../../language/strings"
import ContactItem from "./ContactItem"
import { useWidth } from "../../../../utils/hooks"

interface ContactProps {}

const styles = {
    stack: {
        py: {xs: 12, lg: 16},
        backgroundColor: themeColors.secondary,
        alignItems: "stretch",
        justifyContent: "space-evenly"
    }
}
export default function Contact(props: ContactProps) {
    const width = useWidth();

    let scale = 0;
    if (width < 700) {
        scale = 1
    } else if (width < 1000) {
        scale = 2
    } else {
        scale = 3
    }
    return <Stack direction="column" sx={styles.stack} spacing={{xs: 4, md: 6, lg: 8}} alignItems={"flex-start"} px={{xs: 2, lg: 16}} py={{xs: 2, lg: 8}} id="contact">
        <ContactItem icon={<MailOutlined transform={`scale(${scale})`} htmlColor="white"/>} name={language.contact.mail} />
        <ContactItem icon={<PhoneOutlined transform={`scale(${scale})`} htmlColor="white"/>} name={language.contact.phone} />
        <ContactItem icon={<HouseOutlined transform={`scale(${scale})`} htmlColor="white"/>} name={language.contact.address} />
        <ContactItem icon={<AccountBalanceOutlined transform={`scale(${scale})`} htmlColor="white"/>} name={language.contact.vat} />
    </Stack>
}