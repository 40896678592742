import { Grid, Stack } from "@mui/material";
import cover from "../../../../assets/cover.jpeg";
import logo from "../../../../assets/logo.png";
import Subtitle from "./Subtitle";
import ContactChips from "./ContactChips";
import Title from "./Title";

interface WebCoverProps {
    
};

const styles = {
    main: {
        minHeight: "600px",
        width: "100%",
        backgroundSize: "cover",
    },
    image: {}
}
export default function WebCover(props: WebCoverProps) {
    return <Grid 
        container
        id="home"
        sx={[{ background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(${cover});`}, styles.main]}
    >
        <Grid item p={8} height="50%" xs={12}>
            <img src={logo} alt="Projecten Rob Wens ruwbouw en nieuwbouw" style={styles.image}/>
        </Grid>
        <Grid item p={8} height="50%" xs={12}>
            <Stack direction="column">
                <Title />
                <Subtitle />
                <ContactChips />
            </Stack>
        </Grid>

        <Grid item p={8} height="50%" xs={8}>
        </Grid>
        <Grid item p={8} height="50%" xs={8}>
        </Grid>
    </Grid>

}