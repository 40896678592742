import { Box, Stack } from "@mui/material"
import Services from "./Services"
import Intro from "./Intro"
import Title from "./Title";
import { themeColors } from "../../../../theme/theme";

interface AboutProps {

};

const styles = {
    stack: {
        minHeight: "90vh"
    },
    divider: {
        height: "5px",
        backgroundColor: themeColors.primary,
        width: "300px"
    }
}

export default function About(props: AboutProps) {
    return <Stack direction="column" sx={styles.stack} px={{xs: 1, md: 8, lg: 16, xl: 32}} py={8} id="about">
        <Title />
        <Box sx={styles.divider}/>
        <Intro />
        <Services />
    </Stack>
}