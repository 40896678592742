export const language = {
    menu: {
        home: "Home",
        about: "Over ons",
        contact: "Contact",
        photos: "Realisaties"
    },
    contact: {
        mail: "projectenrw@outlook.com",
        phone: "+324 71 33 18 85",
        address: "Wijngaardstraat 31, 2390 Westmalle",
        vat: "BE0715.959.968"
    },
    about: {
        title: "Oplossingen voor uw nieuwbouw of renovatie",
        intro: `
Met Projecten Rob Wens kiest u voor een lokaal familiebedrijf uit Westmalle. 
Rob deed zijn ervaring op in de bouwonderneming van zijn vader en startte vervolgens zelf Projecten Rob Wens op. 
Met zijn passie voor bouwen en verbouwen, maakt hij van Projecten Rob Wens een betrouwbare partner die al uw (ver)bouwdromen realiseert.
        
Neem zeker een kijkje bij onze realisaties of neem gerust contact op indien u nog vragen heeft.
`,
        services: [
            "Ruwbouw",
            "Niewbouw",
            "Projectbegeleiding",
            "Aannemingen",
            "Aanleg van opritten en terrassen",
            "Afbraakwerken",
            "Renovatiewerken",
            "Metselwerken"
        ]
    },
    title: "Projecten Rob Wens",
    subtitle: "Algemene aanneming, ruw- en nieuwbouwwerken",
    copyright: "AG IT-Solutions",
    sitemap: "Sitemap"
}