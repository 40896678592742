import { Typography } from "@mui/material";
import { language } from "../../../../language/strings";

interface SubtitleProps {};

const styles = {
    text: {
        textAlign: "center",
        fontFamily: "Manrope",
        color: "white",
        fontSize: 28,
        fontWeight: "bold"
    }
}
export default function Subtitle(props: SubtitleProps) {
    return <Typography variant="h2" sx={styles.text} py={{xs: 2, lg: 4}}>{language.subtitle}</Typography>
}