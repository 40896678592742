import { Grid, Stack, Typography } from "@mui/material";
import { language } from "../../../../language/strings";
import { themeColors } from "../../../../theme/theme";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
interface ServicesProps {}

const styles = {
    text: {
        fontSize: {xs: 16, lg: 24},
        color: themeColors.secondary
    }
}

export default function Services(props: ServicesProps) {
    return <Grid container px={{xs: 2, lg: 8}} rowSpacing={4} >
        {
            language.about.services.map((service: string) => {
                return <Grid item xs={12} lg={6} key={service}>
                    <Stack direction="row" spacing={4}>
                        <ConstructionOutlinedIcon />
                        <Typography sx={styles.text}>{service}</Typography>
                    </Stack>
                </Grid>
            })
        }
    </Grid>
}