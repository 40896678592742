import { Grid, Stack } from "@mui/material";
import ContactInfo from "./ContactInfo";
import Links from "./Links";
import { themeColors } from "../../../../theme/theme";

interface WebHeaderProps {}

const styles = {
    topStack: {
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: themeColors.secondary,
        "& .MuiTypography-root": {
            color: "white"
        },
        "& .MuiButton-root": {
            color: "white"
        },
        py: 1,
        px: 4
    }
}
export default function WebHeader(props: WebHeaderProps) {
    return <Grid container>
        <Grid item xs={12}>
            <Stack direction="row" sx={styles.topStack}>
                <ContactInfo />
                <Links />
            </Stack>
        </Grid>
    </Grid>
}