import { MailOutlined, PhoneOutlined } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";
import { language } from "../../../../language/strings";

interface ContactInfoProps {

};

export default function ContactInfo(props: ContactInfoProps) {
    return <Stack direction="row" spacing={4} >
        <Stack direction={"row"} spacing={2}>
            <MailOutlined htmlColor="white"/>
            <Link href={`mailto:${language.contact.mail}`}><Typography>{language.contact.mail}</Typography></Link>
        </Stack>
        <Stack direction={"row"} spacing={2}>
            <PhoneOutlined htmlColor="white"/>
            <Link href={`tel:${language.contact.phone}`}><Typography>{language.contact.phone}</Typography></Link>
        </Stack>
    </Stack>
}