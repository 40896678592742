import { Typography } from "@mui/material";
import { language } from "../../../../language/strings";
import { themeColors } from "../../../../theme/theme";

interface TitleProps {}

const styles = {
    text: {
        fontSize: {xs: 18, lg: 24},
        color: themeColors.secondary,
        whiteSpace: "pre-wrap",
        textAlign: "center",
        py: {xs: 2, lg: 8}
    }
}

export default function Title(props: TitleProps) {
    return <Typography sx={styles.text}>{language.about.intro}</Typography>
}