import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Backdrop, Grid, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { useIsMobile, useWidth } from "../../../../utils/hooks";

interface PhotosProps {};

const styles = {
    stack: {
        pb: 8,
        margin: "auto",
        flexDirection: "column",
        alignItems: "center",
        px: {xs: 1, lg: 16}
    },
    gridItem: {
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        maxWidth: {xs: "400px", lg: "800px"}
    },
    imgLarge: {
        maxWidth: "70%"
    },
    arrowButton: {
        backgroundColor: "white",
        maxWidth: "15%"
    },
    viewStack: {
        width: "100%",
        justifyContent: "space-between"
    }
}

export default function Photos(props: PhotosProps) {
    const [selectedPhoto, setSelectedPhoto] = useState<number | null>(null);

    const images = [
        "131677_379169ba582a4089b255c95b548519bc~mv2.jpg",
        "131677_ae0d1c0792524bb28ad7e7e822954bc0~mv2.jpg",
        "71ec36_042e68e63d574b93815f6a6eeb0b6847~mv2.jpg",
        "71ec36_075e675551194056bda2f445627ca0cd~mv2.jpg",
        "71ec36_14cb6f83f5df49059fd00f89f309fade~mv2.jpg",
        "71ec36_1c5dc0afa8f24a38ab66dce04eeafac6~mv2.jpg",
        "71ec36_3b9cafef239442a68977a07d3b467cba~mv2.jpg",
        "71ec36_43817e975c5249a4ab7cf76a5e4d6b0f~mv2.jpg",
        "71ec36_57a395e448a14ce4b0616d25d7655325~mv2.jpg",
        "71ec36_68f4cd21b21c4e9284d5c131e082192d~mv2.jpg",
        "71ec36_79eacf18c43d4a2f85315930ac30c01b~mv2.jpg",
        "71ec36_7b5d8c70139a4381b86ad22d38dca6b9~mv2.jpg",
        "71ec36_8e96001dc7bd418a83107228963ae8aa~mv2.jpg",
        "71ec36_9164840bdf354d368eb57c3d17887f1d~mv2.jpg",
        "71ec36_9bda50e92833401fb38fa960d499df22~mv2.jpg",
        "71ec36_a215b7ddf2914132a1b45e45b119bd90~mv2.jpg",
        "71ec36_a7ea4897e871497fbedcacf138b33177~mv2.jpg",
        "71ec36_a9a6f5c55719494c9a360bf2a96544aa~mv2.jpg",
        "71ec36_ac81944c9e96473ab98a42ef50700dec~mv2.jpg",
        "71ec36_b17c543d38984c1eaab062d0e7afe18c~mv2.jpg",
        "71ec36_bcd25b3d44cf4474821127d17035eb40~mv2.jpg",
        "71ec36_da77b06b41674eeab8ca12223d99f271~mv2.jpg",
        "71ec36_dc583acd88d6413ca8369d9901e96138~mv2.jpg",
        "71ec36_e2fe7e445ffb4076991a376962112ee7~mv2.jpg",
        "71ec36_eb4be98e6c1e4b7ca044d0fe2e9afb2c~mv2.jpg",
        "71ec36_f7f44b180fbc4363abe67bcaeb6fafd7~mv2.jpg",
        "71ec36_fe819e9f6d0046fd9dbdb7edac02c75f~mv2.jpg",
    ]

    function setPrevious() {
        setSelectedPhoto((current: number | null) => {
            if (current === 0) {
                return images.length - 1
            } else if (current === null) {
                return 0
            } else {
                return current - 1
            }
        })
    };

    function setNext() {
        setSelectedPhoto((current: number | null) => {
            if (current === images.length - 1) {
                return 0
            } else if (current === null) {
                return 0
            } else {
                return current + 1
            }
        })
    }

    return <Stack sx={styles.stack} id="photos" direction="column" alignItems={"center"}>
      <Grid 
        container sx={{justifyContent: "space-around", maxWidth: {xs: "300px", md: "600px", lg: "800px"}}}
        rowSpacing={1} columnSpacing={2}    
    >
        {
            images.map((imageName: string, index: number) => {

                return <Grid key={index.toString()} item xs={12} md={4} lg={3} sx={styles.gridItem} >
                <img
                    onClick={() => setSelectedPhoto(index)}
                    src={window.location.origin + "/photos_small/" + imageName}
                    alt={"Realisaties Projecten Rob Wens Westmalle"}
                    loading="lazy"
                    style={{width: "100%"}}
                />
            </Grid>
            })
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={selectedPhoto !== null}
            onClick={() => setSelectedPhoto(null)}
        >

            <Stack direction={"row"} sx={styles.viewStack}>
                <IconButton 
                sx={styles.arrowButton}
                onClick={(e) => {
                    e.stopPropagation()
                    setPrevious();
                }}> <ArrowLeft /></IconButton>
                <img
                    src={window.location.origin + "/photos_large/" + images[selectedPhoto as any]?.replace(".jpg", ".webp")}
                    alt={"Realisaties Projecten Rob Wens Westmalle"}
                    loading="lazy"
                    style={styles.imgLarge}
                    onClick={(e) => e.stopPropagation()}
                />
                <IconButton 
                sx={styles.arrowButton}
                onClick={(e) => {
                    e.stopPropagation()
                    setNext()
                }}> <ArrowRight /></IconButton>
            </Stack>
        </Backdrop>
        </Grid>
    </Stack>
  }

