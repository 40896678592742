import { Grid, Stack } from "@mui/material";

import cover from "../../../../assets/cover.jpeg";
import logo from "../../../../assets/logo.png";
import Subtitle from "./Subtitle";
import ContactChips from "./ContactChips";
import Title from "./Title";
interface MobileCoverProps {}

const styles = {
    image: {
    },
    logo: {
        textAlign: "center",
    },
    main: {
        backgroundSize: "cover",
        minHeight: "100vh"
    }
}

export default function MobileCover(props: MobileCoverProps) {
    return <Grid 
        container
        id="home"
        sx={[{ background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(${cover});`}, styles.main]}
    >
        <Grid item p={{xs: 2, lg: 8}} height={{xs: undefined, lg: "50%"}} xs={12} sx={styles.logo}>
            <img src={logo} alt="Projecten Rob Wens ruwbouw en nieuwbouw" style={styles.image}/>
        </Grid>
        <Grid item p={{xs: 2, lg: 8}} height={{xs: undefined, lg: "50%"}} xs={12}>
            <Stack direction="column">
                <Title />
                <Subtitle />
                <ContactChips />
            </Stack>
        </Grid>

        <Grid height={{xs: undefined, lg: "50%"}} xs={8}>
        </Grid>
        <Grid height={{xs: undefined, lg: "50%"}} xs={8}>
        </Grid>
    </Grid>

}