import { Button } from "@mui/material"

interface MenuLinkProps {
    name: string
    link: string
}

export default function MenuLink(props: MenuLinkProps) {
    return <Button variant="text" onClick={() => {
        const elem = document.getElementById(props.link);
        window.scrollTo({
            top: elem?.offsetTop ?? 0,
            behavior: "smooth"
        })}}>{props.name}</Button>
}