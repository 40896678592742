import { useIsMobile } from "../../../../utils/hooks";
import MobileCover from "./MobileCover";
import WebCover from "./WebCover";

interface CoverProps {}

export default function Cover(props: CoverProps) {
    const isMobile = useIsMobile();

    if (isMobile) {
        return <MobileCover />
    } else {
        return <WebCover />
    }
}