import { Stack, Typography } from "@mui/material"

interface ContactItemProps {
    icon: JSX.Element
    name: string
}

const styles = {
    item: {
        maxHeight: "100px",
        border: "5px solid white",
        borderRadius: "40px",
        paddingX: {xs: 2, md: 6, lg: 8},
        paddingY: {xs: 2, md: 6, lg: 6},
        flexGrow: 1
    },
    text: {
        fontSize: {xs: 16, md: 24, lg: 36},
        color: "white",
    }
}

export default function ContactItem(props: ContactItemProps) {
    return <Stack direction="row" spacing={{xs: 2, md: 6, lg: 8}} sx={styles.item}>
        {props.icon}
        <Typography sx={styles.text}>{props.name}</Typography>
    </Stack>
}