import { createTheme } from "@mui/material";

let themeColors = {
    primary: "#dd650e",
    secondary: "#2b303b"
}
let theme = createTheme({
    palette: {
        primary: {
            main: themeColors.primary
        },
        secondary: {
            main: themeColors.secondary
        }
    },
    typography: {
        fontFamily: "Manrope",
        body1: {
            color: themeColors.primary,
            fontSize: 16,
            lineHeight: 1.2
        },
        body2: {
            color: themeColors.primary,
            fontSize: 24
        }
    },
    components: {
        MuiStack: {
            defaultProps: {
                spacing: 2,
                alignItems: "center"
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 800,
            xl: 1200
        }
    }
})

export {theme, themeColors}