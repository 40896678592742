import { Typography } from "@mui/material"
import { language } from "../../../../language/strings"
import { themeColors } from "../../../../theme/theme"

interface TitleProps {

}

const styles = {
    text: {
        textAlign: "center",
        backgroundColor: themeColors.primary,
        color: "white",
        borderRadius: "20px",
        py: {xs: 2, lg: 4},
        px: {xs: 4, lg: 12},
        fontSize: {xs: 18, lg: 36},
        fontFamily: "Manrope",
        fontWeight: "bold"
    }
}

export default function Title(props: TitleProps) {
    return <Typography variant="h1" sx={styles.text}>{language.title}</Typography>
}