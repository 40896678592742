import { Mail, Phone } from "@mui/icons-material";
import { Chip, Grid, Link, Stack } from "@mui/material";
import { language } from "../../../../language/strings";

const styles = {
    chip: {
        padding: 2,
        paddingY: 3,
        fontSize: 18
    }
}

interface ContactChipsProps {};

export default function ContactChips(props: ContactChipsProps) {
    return <Grid rowSpacing={4} container justifyContent={"space-around"} alignItems={"center"} maxWidth={{xs: undefined, lg: "600px"}}>
        <Grid pl={0} item xs={12} lg={6} textAlign={"center"}><ContactChip icon={<Mail />} text={language.contact.mail} link={`mailto:${language.contact.mail}`}/></Grid>
        <Grid pl={0} item xs={12} lg={6} textAlign={"center"}><ContactChip icon={<Phone />} text={language.contact.phone} link={`tel:${language.contact.phone}`} /></Grid>
    </Grid>
}

interface ContactChipProps {
    icon: JSX.Element
    text: string
    link: string
}

function ContactChip(props: ContactChipProps) {
    return <Link href={props.link}><Chip label={props.text} icon={props.icon} color={"secondary"} sx={styles.chip}/></Link>
}