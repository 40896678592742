import { Grid } from "@mui/material";
import Header from "../components/Header";
import Cover from "../components/Cover";
import Contact from "../components/Contact/Contact";
import About from "../components/About/About";
import Photos from "../components/Photos/Photos";
import Footer from "../components/Footer/Footer";

interface HomePageProps {

};

export default function HomePage(props: HomePageProps) {
    return <Grid container direction="column">
        <Grid item><Header /></Grid>
        <Grid item><Cover /></Grid>
        <Grid item><About /></Grid>
        <Grid item><Photos /></Grid>
        <Grid item><Contact /></Grid>
        <Grid item><Footer /></Grid>

    </Grid>
}