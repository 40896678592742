import { Typography } from "@mui/material";
import { language } from "../../../../language/strings";
import { themeColors } from "../../../../theme/theme";

interface TitleProps {}

const styles = {
    text: {
        fontSize: 26,
        color: themeColors.secondary,
        fontWeight: "bold",
        textAlign: "center"
    }
}

export default function Title(props: TitleProps) {
    return <Typography sx={styles.text}>{language.about.title}</Typography>
}